<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <div class="filter-bar p-2 mb-3">
        <b-navbar toggleable="xl">
          <b-navbar-toggle target="filter-collapse"></b-navbar-toggle>
          <b-collapse
            id="filter-collapse"
            is-nav
          >
            <button
              @click="resetFilters()"
              class="filter-btn reset"
            >Reset Filters</button>
            <b-form-select
              :options="companies"
              text-field="name"
              value-field="id"
              v-model="filters.company_id"
              plain
              class="select company"
            >
              <template #first>
                <b-form-select-option :value="null">Select Company</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select
              :options="employees"
              text-field="fullname"
              value-field="id"
              v-model="filters.assignee_id"
              plain
              class="select company"
            >
              <template #first>
                <b-form-select-option :value="null">Select Assignee</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-select
              :options="teams"
              text-field="teamname"
              value-field="teamname"
              v-model="filters.team"
              plain
              class="select company"
            >
              <template #first>
                <b-form-select-option :value="null">Select Team</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-checkbox
              v-model="filters.completedLast30Days"
              :value="true"
              :unchecked-value="false"
              class="ml-0 ml-lg-4"
            >
              Completed in the last 30 days
            </b-form-checkbox>

          </b-collapse>
        </b-navbar>
      </div>

      <!-- Sortable Header -->
      <div class="mb-2 p-2 bg-white">
        <b-row>
          <b-col cols="4" class="d-flex justify-content-start">
            <b-button variant="light" @click="sort('name')" class="font-weight-bold text-uppercase bg-white border-white">
              Company
              <b-icon :icon="sortIcon('name')"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-center">
            <b-button variant="light" @click="sort('industry')" class="font-weight-bold text-uppercase bg-white border-white">
              Industry
              <b-icon :icon="sortIcon('industry')"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-end">
            <b-button variant="light" @click="sort('earliestDate')" class="font-weight-bold text-uppercase bg-white border-white">
              Due Date
              <b-icon :icon="sortIcon('earliestDate')"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-for="(group, index) in groupedTickets" :key="group.id" class="mb-2">
        <b-card no-body class="mb-1">
          <b-card-header class="p-2 d-flex align-items-center justify-content-between bg-light border-light" @click="toggleCollapse(index)">
            <b-button block :variant="group._showDetails ? 'dark' : 'dark'" class="text-reset d-flex align-items-center w-100 bg-light border-light">
              <div class="d-flex align-items-center" style="flex: 1;">
                <b-icon :icon="group._showDetails ? 'chevron-up' : 'chevron-down'" class="mr-2"></b-icon>
                <span class="font-weight-bold text-uppercase">{{ group.name }}</span>
                <span class="red-badge ml-2">{{ group.tickets.length }}</span>
              </div>
              <div class="text-muted font-weight-bold text-uppercase" style="flex: 1; text-align: center;">{{ group.industry }}</div>
              <div class="text-muted font-weight-bold" style="flex: 1; text-align: right;">{{ formatDate(group.earliestDate) }}</div>
            </b-button>
          </b-card-header>
          <b-collapse :visible="group._showDetails">
            <b-card-body>
              <b-table small :items="group.tickets" :fields="ticketFields" bordered>
                <template #cell(duedate)="data">
                  <b-form-datepicker
                    @input="addNote(data.item.id, 'duedate', null, data.item.previous_duedate, null, $event, data.item, 'duedate')"
                    v-model="data.item.duedate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    style="width: auto;"
                  ></b-form-datepicker>
                </template>
                <template #cell(type)="data">
                  {{ data.item.type }}<br>
                  <span v-if="data.item.onboarding === 1" class="red-badge">OB</span><br>
                  {{ data.item.fordate | MMM(data.item.type) }}
                </template>
                <template #cell(dataentry_id)="data">
                  <div class="">
                    <b-row class="text-left mb-2">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'assignee', data.item.dataentry_id, data.item.dataentry_assignee_name, data.item.dataentry_assignee_id, $event, data.item, 'dataentry_assignee_id')"
                        >
                          <option
                            v-for="e in employees"
                            :key="e.id"
                            :value="e.id"
                            :selected="+e.id == +data.item.dataentry_assignee_id"
                          >
                            {{ e.firstname }} {{ e.lastname }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                    <b-row class="text-left">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'status', data.item.dataentry_id, data.item.dataentry_status_name, data.item.dataentry_status_id, $event, data.item, 'dataentry_status_id')"
                        >
                          <option
                            v-for="s in statusOptionsDE"
                            :key="s.id"
                            :value="s.id"
                            :selected="+s.id == +data.item.dataentry_status_id"
                          >
                            {{ s.name }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template #cell(preliminary_id)="data">
                  <div class="w-90 mx-auto">
                    <b-row class="text-left mb-2">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'assignee', data.item.preliminary_id, data.item.preliminary_assignee_name, data.item.preliminary_assignee_id, $event, data.item, 'preliminary_assignee_id')"
                        >
                          <option
                            v-for="e in employees"
                            :key="e.id"
                            :value="e.id"
                            :selected="+e.id == +data.item.preliminary_assignee_id"
                          >
                            {{ e.firstname }} {{ e.lastname }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                    <b-row class="text-left">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'status', data.item.preliminary_id, data.item.preliminary_status_name, data.item.preliminary_status_id, $event, data.item, 'preliminary_status_id')"
                        >
                          <option
                            v-for="s in statusOptionsPR"
                            :key="s.id"
                            :value="s.id"
                            :selected="+s.id == +data.item.preliminary_status_id"
                          >
                            {{ s.name }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template #cell(finalization_id)="data">
                  <div class="w-90 mx-auto">
                    <b-row class="text-left mb-2">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'assignee', data.item.finalization_id, data.item.finalization_assignee_name, data.item.finalization_assignee_id, $event, data.item, 'finalization_assignee_id')"
                        >
                          <option
                            v-for="e in employees"
                            :key="e.id"
                            :value="e.id"
                            :selected="+e.id == +data.item.finalization_assignee_id"
                          >
                            {{ e.firstname }} {{ e.lastname }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                    <b-row class="text-left">
                      <b-col cols="12">
                        <select
                          class="w-100"
                          @change="addNote(data.item.id, 'status', data.item.finalization_id, data.item.finalization_status_name, data.item.finalization_status_id, $event, data.item, 'finalization_status_id')"
                        >
                          <option
                            v-for="s in statusOptionsF"
                            :key="'status' + s.id"
                            :value="s.id"
                            :selected="+s.id == +data.item.finalization_status_id"
                          >
                            {{ s.name }}
                          </option>
                        </select>
                      </b-col>
                    </b-row>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <!-- <b-button
                    v-b-tooltip.hover.topright="'Code'"
                    variant="success"
                    size="sm"
                    class="mr-1"
                    @click="$router.push('/bookkeeping/code/' + data.item.company_id + '/' + yearmonth(data.item.fordate))"
                  >
                    <b-icon icon="wrench"></b-icon>
                  </b-button> -->

                  <b-button
                    v-b-tooltip.hover.topleft="'Add Note'"
                    size="sm"
                    @click="addNote(data.item.id, 'note')"
                    class="mr-1"
                  >
                    <b-icon icon="journal-plus"></b-icon>
                  </b-button>

                  <b-button
                    v-b-tooltip.hover.bottom="'View Notes'"
                    size="sm"
                    class="mr-1"
                    @click="openNotes(data.item.id)"
                  >
                    <b-icon icon="eye"></b-icon>
                  </b-button>

                  <b-button
                    variant="danger"
                    v-b-tooltip.hover.bottom="'Delete Ticket'"
                    size="sm"
                    class="mr-1"
                    @click="deleteTicket(data.item.id)"
                    v-if="isAdmin"
                  >
                    <b-icon icon="trash-fill"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <!-- <b-card class="text--black card-border q-table-container">
        <b-table
          :items="groupedTickets"
          :fields="groupedFields"
          class="q-table"
        >
          <template #cell(name)="row">
            <b-button variant="link" @click="toggleCollapse(row.index)">
              <b-icon :icon="row.item._showDetails ? 'chevron-up' : 'chevron-down'"></b-icon>
              {{ row.item.name }} <span class="red-badge">{{ row.item.tickets.length }}</span>
            </b-button>
          </template>

          <template #row-details="row">
            <b-table :items="row.item.tickets" :fields="ticketFields" small bordered>
            </b-table>
          </template>
        </b-table>
        <b-table
          :items="tickets"
          :fields="fields"
          class="q-table"
        >
        </b-table>
      </b-card> -->
    </b-overlay>

    <b-modal
      v-model="notesmodal"
      title="Add Notes"
      @hidden="resetNote()"
      @ok="saveChange"
      ok-variant="primary"
      no-close-on-backdrop
      no-close-on-esc
      :busy="loading"
      :ok-disabled="loading"
    >
      <b-form-group
        label="Notes"
        label-align="left"
        :description="(note ? note.length : 0) + '/100'"
      >
        <b-textarea
          v-model="note"
          maxlength="100"
          required
        ></b-textarea>
      </b-form-group>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>

    <b-modal
      v-model="shownotes"
      @hidden="notelist = []"
      hide-footer
      scrollable
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div
        v-for="n in notelist"
        :key="n.id"
      >
        <fieldset class="note">
          <legend>{{ n.author }}</legend>
          <small>{{ n.updated }}</small><br>
          {{ n.note }}
        </fieldset>
      </div>
      <div
        v-if="notelist.length == 0 && !loadingnotes"
        class="text-center"
      >
        No notes to be displayed
      </div>
    </b-modal>

    <b-modal
      id="delete-confirmation"
      title="Confirm Deletion"
      @ok="confirmDelete()"
      ok-title="Delete"
      ok-variant="danger"
      @cancel="cancelDelete()"
      cancel-title="Cancel"
      cancel-variant="secondary"
    >
      <p class="mb-0">Are you sure you want to delete this ticket?</p>
    </b-modal>
  </b-container>
</template>
<script>
import moment from 'moment'

import TicketService from '@/services/TicketService'
import AdminService from '@/services/AdminService'
import UserService from '@/services/UserService'

export default {
  data () {
    return {
      tickets: [],
      groupedTickets: [],
      groupedFields: [
        { key: 'name', label: 'Company', sortable: true },
        { key: 'industry', label: 'Industry', sortable: true }
      ],
      ticketFields: [
        { key: 'duedate', label: 'Due Date' },
        { key: 'type', label: 'Type' },
        { key: 'dataentry_id', label: 'Data Entry', tdClass: 'align-middle' },
        { key: 'preliminary_id', label: 'Preliminary', tdClass: 'align-middle' },
        { key: 'finalization_id', label: 'Finalization', tdClass: 'align-middle' },
        { key: 'actions', label: '', tdClass: 'align-middle' }
      ],
      // fields: [
      //   { key: 'company', label: 'Company', tdClass: 'align-middle' },
      //   { key: 'industry', label: 'Industry', tdClass: 'align-middle' },
      //   { key: 'duedate', label: 'Due Date', tdClass: 'align-middle' },
      //   { key: 'type', label: 'Type', tdClass: 'align-middle' },
      //   { key: 'dataentry_id', label: 'Data Entry', tdClass: 'align-middle' },
      //   { key: 'preliminary_id', label: 'Preliminary', tdClass: 'align-middle' },
      //   { key: 'finalization_id', label: 'Finalization', tdClass: 'align-middle' },
      //   { key: 'actions', label: '', tdClass: 'align-middle' }
      // ],
      isAdmin: false,
      statusOptionsPR: [],
      statusOptionsF: [],
      statusOptionsDE: [],
      employees: [],
      companies: [],
      teams: [],
      tempStatus: {
        item: null,
        property: null,
        action: null,
        taskid: null,
        ticketid: null,
        oldvalue: null,
        oldvalue_id: null,
        newvalue: null,
        newvalue_id: null
      },
      filters: {
        assignee_id: null,
        company_id: null,
        team: null,
        completedLast30Days: false
      },
      notesmodal: false,
      note: null,
      changetype: null,
      notelist: [],
      shownotes: false,
      loading: false,
      loadingnotes: false,
      ticketToDelete: null,
      currentSort: 'company',
      currentSortDir: 'asc'
    }
  },

  async mounted () {
    await this.getBookkeepers()
    this.getCompanies()
    this.getTeams()
    this.getTicketStatuses()

    if (this.$route.query.assignee && this.$route.query.assignee > 0) {
      this.filters.assignee_id = this.$route.query.assignee
      this.$router.replace({ ...this.$router.currentRoute, query: {} })
    }

    this.init()
  },

  methods: {
    async getBookkeepers () {
      return AdminService.getBookkeepers().then(
        (response) => {
          if (response && response.data && response.data.info && response.data.info.length) {
            this.employees = response.data.info.sort((a, b) => ('' + a.fullname).localeCompare(b.fullname))
            UserService.getUserInfo().then(
              user => {
                this.employees.some(e => {
                  if (e.id === user.id) {
                    this.filters.assignee_id = e.id
                    return true
                  }
                  return false
                })
              }
            )
          }
        }
      )
    },

    formatDate (date) {
      return date.toISOString().slice(0, 10)
    },

    sort (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.currentSort = s
        this.currentSortDir = 'asc'
      }
      this.groupedTickets.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === 'desc') modifier = -1
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier
        return 0
      })
    },

    sortIcon (prop) {
      if (this.currentSort === prop) {
        return this.currentSortDir === 'asc' ? 'chevron-down' : 'chevron-up'
      }
      return 'sort'
    },

    getCompanies () {
      this.companies = []
      AdminService.getCompanies().then(
        (res) => {
          this.companies = res.sort((a, b) => ('' + a.name).localeCompare(b.name))
        }
      )
    },

    getTicketStatuses () {
      TicketService.getTicketStatuses().then(
        (response) => {
          if (response.data.info) {
            this.statusOptionsPR = response.data.info.statusOptionsPR
            this.statusOptionsF = response.data.info.statusOptionsF
            this.statusOptionsDE = response.data.info.statusOptionsDE
          }
        }
      )
    },

    getTeams () {
      this.teams = []
      AdminService.getTeams().then(
        (res) => {
          this.teams = res.data.info.sort((a, b) => ('' + a.teamname).localeCompare(b.teamname))
        }
      )
    },

    addNote (ticketid, changetype, taskid = null, previous = null, previousid = null, event = null, item = null, property = null) {
      if (ticketid == null) {
        return false
      }
      if (changetype) {
        this.changetype = changetype
      }

      if (changetype === 'duedate') {
        previous = moment(previous).format('YYYY-MM-DD')
      }

      this.tempStatus.newvalue = event
      this.tempStatus.oldvalue = previous
      this.tempStatus.oldvalue_id = previousid
      this.tempStatus.action = changetype
      this.tempStatus.taskid = taskid
      this.tempStatus.ticketid = +ticketid
      this.tempStatus.item = item
      this.tempStatus.property = property

      if (event && event.target && event.target.value && +event.target.value > 0 && ticketid != null) {
        this.tempStatus.newvalue = event.target.selectedOptions[0].text
        this.tempStatus.newvalue_id = +event.target.value
      }

      this.notesmodal = true
    },

    deleteTicket (ticketId) {
      console.log('delete ticket called: ', ticketId)
      this.ticketToDelete = ticketId
      this.$bvModal.show('delete-confirmation')
    },

    confirmDelete () {
      const filt = {}
      filt.ticket_id = this.ticketToDelete
      console.log('main delete function called!')
      if (this.ticketToDelete) {
        TicketService.deleteTicket(filt)
          .then(response => {
            this.$bvToast.toast('Ticket has been deleted successfully.', {
              title: 'Success',
              variant: 'success',
              solid: true
            })
            this.init()
          })
          .catch(error => {
            console.error('Delete operation failed:', error)
            this.$bvToast.toast('Failed to delete ticket.', {
              title: 'Error',
              variant: 'danger',
              solid: true
            })
          })
      }
    },
    cancelDelete () {
      this.ticketToDelete = null
    },

    resetNote () {
      this.$nextTick(() => {
        this.tempStatus.action = null
        this.tempStatus.oldvalue = null
        this.tempStatus.newvalue = null
        this.tempStatus.taskid = null
        this.tempStatus.ticketid = null

        this.note = null
        this.changetype = null
      })
      this.init()
    },

    getdate (str) {
      return new Date(moment(str).toArray())
    },

    yearmonth (date) {
      return moment(date).format('YYYY-MM')
    },

    saveChange (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.note || this.note.replace(/^\s+|\s+$/g, '') === '') {
        this.$notify({
          group: 'notices',
          title: 'Note required',
          text: 'Please enter a note for your change',
          type: 'error'
        })
        return false
      }
      this.loading = true
      const data = {
        task_id: this.tempStatus.taskid,
        note: this.note,
        action: this.tempStatus.action,
        oldvalue: this.tempStatus.oldvalue,
        newvalue: this.tempStatus.newvalue
      }
      if (this.changetype === 'status') {
        data.status_id = this.tempStatus.newvalue_id
      } else if (this.changetype === 'duedate') {
        data.duedate = this.tempStatus.newvalue
      } else if (this.changetype === 'assignee') {
        data.assignee_id = this.tempStatus.newvalue_id
      }

      if (data) {
        TicketService.update(this.tempStatus.ticketid, data).then(
          (response) => {
            this.$aimNotify.notify(response)
            this.notesmodal = false
          },
          (err) => {
            this.$aimNotify.error(err.response)
          }
        ).finally(() => { this.loading = false })
      }
      this.init()
    },

    openNotes (ticketId) {
      this.loadingnotes = true
      this.notelist = []
      TicketService.getNotes(ticketId).then(
        (response) => {
          if (response.data.info) {
            this.notelist = response.data.info
            this.shownotes = true
          }
        }
      ).finally(() => { this.loadingnotes = false })
    },

    init () {
      if (this.loading) {
        return false
      }
      this.loading = true
      this.tickets = []
      this.groupedTickets = []
      const filt = {}
      if (this.filters.company_id) {
        filt.company_id = this.filters.company_id
      }
      if (this.filters.assignee_id) {
        filt.assignee_id = this.filters.assignee_id
      }
      if (this.filters.team) {
        filt.team = this.filters.team
      }
      if (this.filters.completedLast30Days) {
        filt.completedLast30Days = this.filters.completedLast30Days
      }
      UserService.getUserInfo().then(
        usr => {
          this.isAdmin = usr.isAdmin

          TicketService.getTickets(filt).then(
            (response) => {
              if (response.data.info) {
                this.tickets = response.data.info
                this.groupedTickets = this.groupTickets(response.data.info)
              }
            }
          ).catch((err) => {
            this.$aimNotify.error(err.response)
          }).finally(() => {
            this.loading = false
          })
        }
      )
    },

    latestDueDate (tickets) {
      const earliest = tickets.reduce((earliestDate, ticket) => {
        const currentDate = moment.utc(ticket.duedate).toDate()
        console.log('Ticket Date: ', ticket.duedate)
        console.log('Parsed UTC Date: ', currentDate)
        console.log('Earliest Date Calculated: ', earliestDate.toISOString())
        return earliestDate < currentDate ? earliestDate : currentDate
      }, new Date(8640000000000000))
      return earliest.toISOString().slice(0, 10)
    },
    toggleCollapse (index) {
      this.$set(this.groupedTickets[index], '_showDetails', !this.groupedTickets[index]._showDetails)
    },
    groupTickets (tickets) {
      // Example of how to group tickets
      const groups = {}
      tickets.forEach(ticket => {
        if (!groups[ticket.company_id]) {
          groups[ticket.company_id] = {
            name: ticket.company,
            industry: ticket.industry,
            tickets: [],
            earliestDate: new Date(8640000000000000)
          }
        }
        const ticketDate = moment.utc(ticket.duedate).toDate()
        groups[ticket.company_id].tickets.push(ticket)
        if (ticketDate < groups[ticket.company_id].earliestDate) {
          groups[ticket.company_id].earliestDate = ticketDate
        }
      })
      return Object.values(groups)
    },
    resetFilters () {
      this.filters = {
        assignee_id: null,
        company_id: null,
        team: null,
        completedLast30Days: false
      }
    }
  },

  computed: {
    noTickets () {
      return this.tickets.length > 0
    }
  },

  watch: {
    bookkeeper (n, o) {
      if (+n !== +o) {
        this.init()
      }
    },

    filters: {
      handler: function (n, o) {
        if (n) {
          this.init()
        }
      },
      deep: true
    }
  },

  filters: {
    dateNoTime (val) {
      const response = moment(val)
      if (response.isValid()) {
        return response.format('YYYY-MM-DD')
      } else {
        return val
      }
    },

    MMM (val, type) {
      const response = moment(val)
      if (response.isValid()) {
        if (type === 'Semi-Annually') {
          const month = response.month()
          if (month >= 0 && month <= 5) {
            return 'Jan-Jun'
          } else if (month >= 6 && month <= 11) {
            return 'Jul-Dec'
          }
        } else {
          return response.format('MMM')
        }
      } else {
        return val
      }
    }
  }
}
</script>
<style scoped>
.red-badge {
  background-color: #BE191E; /* using the hex code directly */
  color: white;
  padding: 2px 6px;
  font-size: 14px;
  border-radius: 5px;
}

b-card-header, b-card-body, b-button {
  background-color: #FFFFFF; /* Set to white or any preferred light color */
  border: none; /* Remove borders */
}

b-button {
  display: flex; /* Use flexbox to layout button contents */
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Distribute space between elements */
  font-weight: bold;
  color: #333; /* Dark gray text color for better readability */
  text-decoration: none; /* No underline */
  padding: 5px 10px;
}

b-icon {
  color: #333; /* Ensure icons are visible against a light background */
}

.text-muted {
  color: #393b3d; /* Slightly darker muted text for better contrast */
  white-space: nowrap; /* Prevent text from wrapping */
}
</style>
